import {useCallback, useState} from 'react'

import styled from 'styled-components'

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 738px;
  max-height: 492px;
  padding: 20px 16px;
`

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  max-height: 140px;
  object-fit: contain;
`

interface ImageProps {
  src: string
  alt: string
}

export default function ProductImage({src, alt}: ImageProps): JSX.Element {
  const [img, setImg] = useState(src || '/images/placeholder.png')

  const onError = useCallback(() => {
    setImg('/images/placeholder.png')
  }, [])

  return (
    <ImageWrapper>
      <CardImg src={img} alt={alt} onError={onError} />
    </ImageWrapper>
  )
}
