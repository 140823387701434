import {useCallback} from 'react'

import Link from 'next/link'
import {palette} from 'styled-tools'
import styled from 'styled-components'

import settings from '@festi/common/constants/settings'
import {styledTheme} from '@festi/common/themes'
import {VariantList, VariantDetail} from '@festi/common/api/rest'
import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {AddToCartButton} from '@festi/common/components/cart'
import {H5, PSmall} from '@festi/common/components/typography'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {useEvents} from '@festi/common/contexts'
import useTagmanager from '@festi/common/utils/tagmanager'

import ProductTags from './utilities/ProductTags'
import ProductImage from './utilities/ProductImage'
import ProductCardLoader from './utilities/ProductCardLoader'
import ProductActionButtons from './utilities/ProductActionButtons'

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 12px;
  background-color: ${palette('white')};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 12px;
    pointer-events: none;
    z-index: 1;
  }

  &:hover:before {
    border-color: ${palette('ui20Solid')};
  }
`

const Description = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 36px 16px 16px;
`

const FunctionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 16px;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
`

const ActionButtons = styled.div`
  display: flex;
  gap: 20px;
`

interface Props {
  image: {
    url: string
    alt: string
  }
  origin?: string
  variant?: VariantList | VariantDetail
  vertical?: boolean
  position?: number
  userPrice?: AugmentedUserPrice
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export default function NewProductCard({
  image,
  origin,
  variant,
  vertical,
  position,
  userPrice,
  onClick,
}: Props) {
  const {sendClickEvent} = useEvents()
  const {sendEcommerceClickEvent} = useTagmanager()

  const price =
    userPrice?.price?.price ||
    variant?.listings?.[settings.channel]?.price?.price ||
    0
  const lowestPrice =
    userPrice?.lowestPrice ||
    variant?.listings?.[settings.channel]?.price?.lowestPrice ||
    0
  const discountedPrice =
    userPrice?.userPrice ||
    variant?.listings?.[settings.channel]?.price?.discountedPrice ||
    0

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      !!onClick && onClick(e)
      // TODO: Combine events for google analytics and algolia
      sendEcommerceClickEvent(variant, position, origin)

      sendClickEvent(
        'clickedObjectIDsAfterSearch',
        'Product Clicked After Search',
        variant.sku,
        position,
      )
    },
    [
      origin,
      variant,
      position,
      onClick,
      sendClickEvent,
      sendEcommerceClickEvent,
    ],
  )

  if (variant === undefined) return <ProductCardLoader vertical={vertical} />

  return (
    <CardWrapper style={{flexDirection: vertical ? 'row' : 'column'}}>
      <ProductTags variant={variant} />

      <Link
        href={`/vorur/${variant.product.slug}/${variant.sku}`}
        onClick={handleClick}
      >
        <Description style={{flexDirection: vertical ? 'row' : 'column'}}>
          <ProductImage src={image.url} alt={image.alt} />

          <div>
            <H5 withGutter>{variant.name || ''}</H5>
            <PSmall style={{color: styledTheme.palette.ui40Solid}} bold>
              {variant.sku}
            </PSmall>
            <PSmall style={{color: styledTheme.palette.ui80Solid}} withGutter>
              {variant.product.shortDescription.substring(0, 80)}
            </PSmall>
          </div>
        </Description>
      </Link>

      <FunctionWrapper style={{flexDirection: vertical ? 'column' : 'row'}}>
        <PriceWrapper>
          <ProductPrices
            price={price}
            lowestPrice={lowestPrice}
            discountedPrice={discountedPrice}
          />
        </PriceWrapper>

        {vertical && (
          <AddToCartButton variant={variant} buttonText="Bæta í körfu" />
        )}

        <ActionButtons>
          <ProductActionButtons variant={variant} vertical={vertical} />
        </ActionButtons>
      </FunctionWrapper>
    </CardWrapper>
  )
}
